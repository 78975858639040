import { Input, Radio, Select, Tabs } from 'antd';
import ConnectedPage from 'business/user/helpers/connectedPage';
import Flex from 'ui/flex';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import { useOsrFilters } from 'business/resources/osr/services/useOsrFilters';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import NotFoundPage from 'ui/notFoundPage';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { useGetCountriesListQuery } from 'generated/graphql';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import Button from 'ui/button';
import Table from 'ui/table';
import { trademarkHandleTableRowColor } from 'business/resources/osr/components/common/osrRowColors';
import { RangePicker } from 'ui/date/datePicker';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';
import TrademarkAcumass from 'business/resources/osr/components/trademark/trademarkAcumass';
import OsrColorDescription from 'business/resources/osr/components/common/osrColorDescription';
import { useGetTrademarkOsrData } from 'business/resources/osr/services/useGetTrademarkOsrData';
import { TRADEMARK_RENEW_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';
import TrademarkAffidavits from 'business/resources/osr/components/trademark/trademarkAffidavits';
import TrademarkAnomaly from 'business/resources/osr/components/trademark/trademarkAnomaly';

const OsrTrademarkPage = () => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const { filters, actions } = useOsrFilters();
  const { data: toRenewData, tableProps: toRenewTableProps } =
    useGetTrademarkOsrData('TO_RENEW', filters);

  const { data: renewedData, tableProps: renewedTableProps } =
    useGetTrademarkOsrData('RENEWED', filters);

  const { data } = useGetCountriesListQuery();

  const countries = data?.country ?? [];

  const { generateExcel } = useGenerateExcelFile('trademark', filters);

  if (!user.osrAccess) {
    return <NotFoundPage />;
  }

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <ConnectedPage translationKey="dashboard">
      <Spacer direction="vertical" size="small">
        <Flex justify="space-between">
          <h1 className="headerTitle">
            {t('pages.osr.title', { context: 'trademark' })}
          </h1>
        </Flex>

        <div style={{ width: '33%' }}>
          <Flex column>
            <Input
              defaultValue={filters.companyRef}
              placeholder={t('pages.osr.filters.clientRef')}
              onChange={(e) => actions.onChangeCompanyRef(e.target.value)}
            />
          </Flex>
        </div>
        <Flex>
          <Select
            showSearch
            mode="multiple"
            style={{ width: '33%' }}
            placeholder="Filtrer par pays"
            optionFilterProp="children"
            onChange={(value) => actions.onChangeCountries(value)}
            filterOption={filterOption}
            options={countries.map((c) => {
              return { label: c.labelFr, value: c.code };
            })}
          />
        </Flex>
        <div>
          <Tabs
            items={[
              // RENEWS
              {
                key: 'RenewTrademarkCase',
                label: t('pages.osr.title', { context: 'tabRenew' }),
                children: (
                  <OsrTabs
                    actions={
                      <Spacer direction="vertical" size="small">
                        <Flex alignItems="center" justify="space-between">
                          <Flex column style={{ width: '33%' }}>
                            <RangePicker
                              allowClear
                              inputReadOnly={true}
                              defaultValue={[
                                startOfYear(new Date()),
                                endOfYear(new Date()),
                              ]}
                              onChange={(e) => {
                                actions.onChangeRangeDate(
                                  e && e[0]
                                    ? new Date(endOfDay(e[0]))
                                    : e?.[0] ?? null,
                                  e && e[1]
                                    ? new Date(endOfDay(e[1]))
                                    : e?.[1] ?? null,
                                );
                              }}
                            />
                          </Flex>

                          <Flex>
                            <Radio.Group
                              defaultValue={'all'}
                              onChange={(e) =>
                                actions.onChangeOffice(e.target.value)
                              }
                            >
                              <Radio value={'all'}>
                                {t('pages.osr.filters.office', {
                                  context: 'all',
                                })}
                              </Radio>
                              <Radio value={'Europe'}>
                                {t('pages.osr.filters.office', {
                                  context: 'europe',
                                })}
                              </Radio>
                              <Radio value={'France'}>
                                {t('pages.osr.filters.office', {
                                  context: 'france',
                                })}
                              </Radio>
                              <Radio value={'InterSynthese'}>
                                {t('pages.osr.filters.office', {
                                  context: 'interSynth',
                                })}
                              </Radio>
                              <Radio value={'InterDetail'}>
                                {t('pages.osr.filters.office', {
                                  context: 'interDetail',
                                })}
                              </Radio>
                              <Radio value={'Others'}>
                                {t('pages.osr.filters.office', {
                                  context: 'others',
                                })}
                              </Radio>
                            </Radio.Group>
                          </Flex>
                        </Flex>

                        <Flex>
                          <Button
                            type="primary"
                            onClick={() => generateExcel('RENEWS')}
                          >
                            Exporter les Renouvellements
                          </Button>
                        </Flex>
                      </Spacer>
                    }
                    tabsItems={[
                      {
                        key: 'toRenew',
                        label: `Marques à renouveler (${toRenewTableProps.pagination?.total})`,
                        children: (
                          <Spacer direction="vertical" size="small">
                            <Flex justify="flex-end">
                              <OsrColorDescription
                                colors={[
                                  {
                                    color: '#90EE90',
                                    label: 'Renouv. Possible',
                                  },
                                  {
                                    color: '#FFA502',
                                    label: 'Délai de grace',
                                  },
                                  {
                                    color: '#f27979',
                                    label: 'Hors délai',
                                  },
                                ]}
                              />
                            </Flex>
                            <Table
                              editButtonEnabled={false}
                              defaultColumns={TRADEMARK_RENEW_COLUMNS}
                              name="trademarkToRenewTable"
                              handleTableRowColor={trademarkHandleTableRowColor}
                              {...toRenewTableProps}
                              dataSource={toRenewData}
                            />
                          </Spacer>
                        ),
                      },
                      {
                        key: 'renewed',
                        label: `Marques renouvelées (${renewedTableProps.pagination?.total})`,
                        children: (
                          <Table
                            editButtonEnabled={false}
                            defaultColumns={TRADEMARK_RENEW_COLUMNS}
                            name="trademarkRenewedTable"
                            {...renewedTableProps}
                            dataSource={renewedData}
                          />
                        ),
                      },
                    ]}
                  />
                ),
              },
              // Affidavit of use
              {
                key: 'AffidavitsList',
                label: `Liste des affidavits`,
                children: <TrademarkAffidavits filters={filters} />,
              },

              // ACUMASS

              {
                key: 'Acumass',
                label: 'Mise à jour Acumass',
                children: <TrademarkAcumass filters={filters} />,
              },

              // ANOMALIES
              {
                key: 'TrademarkAnomaly',
                label: t('pages.osr.title', { context: 'tabAnomaly' }),
                children: <TrademarkAnomaly filters={filters} />,
              },
            ]}
          />
        </div>
      </Spacer>
    </ConnectedPage>
  );
};

export default OsrTrademarkPage;
