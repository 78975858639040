import { useReducer } from 'react';
import { removeAccent } from 'technical/format/string';
import { OsrFilterActions, OsrFilterState } from './types';
import { endOfYear, startOfYear } from 'date-fns';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

const SET_COMPANY_REF = 'SET_COMPANY_REF';
const SET_RANGE_DATE = 'SET_RANGE_DATE';
const SET_OFFICE = 'SET_OFFICE';
const RESET_OFFICE_FILTER = 'RESET_OFFICE_FILTER';
const SET_EVENT_STATUS = 'SET_EVENT_STATUS';
const SET_AR = 'SET_AR';
const SET_COUNTRIES = 'SET_COUNTRIES';

export interface IFiltersActions {
  onChangeOffice: (newOffice: string) => void;
  onChangeConfirmationReceipt: (confirmed: boolean) => void;
  onChangeRangeDate: (from: Date | null, to: Date | null) => void;
  onChangeCompanyRef: (ref: string) => void;
  onChangeCountries: (countries: string[]) => void;
  onChangeEventStatus: (data: CheckboxValueType[]) => void;
}

interface UseFiltersReturn {
  filters: OsrFilterState;
  actions: IFiltersActions;
}

const initialState: OsrFilterState = {
  companyRef: 'DECATHLON',
  dueDate: {
    gte: startOfYear(new Date()),
    lte: endOfYear(new Date()),
  },
  ar: true,
  eventStatus: ['ENCOUR', 'SIMULE', 'TRAITE'],
};

function filterReducer(
  state: OsrFilterState,
  action: OsrFilterActions,
): OsrFilterState {
  switch (action.type) {
    case SET_EVENT_STATUS:
      return {
        ...state,
        eventStatus: action.payload.value,
      };
    case RESET_OFFICE_FILTER:
      return {
        ...state,
        office: undefined,
        countryCode: undefined,
      };
    case SET_AR:
      return {
        ...state,
        ar: action.payload.value,
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countryCode: action.payload.value.length
          ? action.payload.value
          : undefined,
      };

    case SET_OFFICE:
      return {
        ...state,
        office: action.payload.value,
      };
    case SET_COMPANY_REF:
      return {
        ...state,
        companyRef: removeAccent(action.payload.value),
      };

    case SET_RANGE_DATE:
      if (action.payload.from === null && action.payload.to === null) {
        return {
          ...state,
          dueDate: undefined,
        };
      }
      return {
        ...state,
        dueDate: {
          gte: action.payload.from,
          lte: action.payload.to,
        },
      };
  }
  return initialState;
}
export function useOsrFilters(): UseFiltersReturn {
  const [state, dispatch] = useReducer(filterReducer, initialState);
  const onChangeRangeDate = (from: Date | null, to: Date | null) => {
    dispatch({
      type: 'SET_RANGE_DATE',
      payload: { from, to },
    });
  };

  const onChangeCountries = (countries: string[]) => {
    dispatch({
      type: 'SET_COUNTRIES',
      payload: {
        value: countries,
      },
    });
  };

  const onChangeCompanyRef = (value: string) => {
    dispatch({
      type: 'SET_COMPANY_REF',
      payload: { value },
    });
  };

  const onChangeOffice = (office: string) => {
    if (office === 'all') {
      return dispatch({
        type: 'RESET_OFFICE_FILTER',
      });
    }

    return dispatch({
      type: 'SET_OFFICE',
      payload: { value: office },
    });
  };

  const onChangeEventStatus = (data: CheckboxValueType[]) => {
    return dispatch({
      type: 'SET_EVENT_STATUS',
      payload: { value: data },
    });
  };

  const onChangeConfirmationReceipt = (value: boolean) => {
    return dispatch({
      type: 'SET_AR',
      payload: {
        value,
      },
    });
  };

  return {
    filters: state,
    actions: {
      onChangeOffice,
      onChangeConfirmationReceipt,
      onChangeRangeDate,
      onChangeCompanyRef,
      onChangeEventStatus,
      onChangeCountries,
    },
  };
}
