import Button from 'ui/button';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import Spacer from 'ui/spacer';
import Flex from 'ui/flex';
import OsrColorDescription from 'business/resources/osr/components/common/osrColorDescription';
import Table from 'ui/table';
import { OsrFilterState } from 'business/resources/osr/services/types';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { useGetDesignOsrData } from 'business/resources/osr/services/useGetDesignOsrData';
import { DESIGN_RENEW_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';
import {
  handleRenewedDesignColor,
  handleToRenewDesignColor,
} from 'business/resources/osr/components/common/osrRowColors';
import { Radio } from 'antd';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';
import { RangePicker } from 'ui/date/datePicker';
import { useTranslation } from 'react-i18next';

interface IRenewalTracking {
  filters: OsrFilterState;
  onChangeConfirmationReceipt: (data: boolean) => void;
  onChangeRangeDate: (from: Date | null, to: Date | null) => void;
  onChangeOffice: (value: any) => void;
}

const RenewalTracking = (props: IRenewalTracking) => {
  const { t } = useTranslation();

  const { data: renewedData, tableProps: renewedTableProps } =
    useGetDesignOsrData({ ...props.filters }, 'renewed');

  const { data: toRenewData, tableProps: toRenewTableProps } =
    useGetDesignOsrData({ ...props.filters, ar: true }, 'toRenew');

  const { generateExcel, loading } = useGenerateExcelFile(
    'design',
    props.filters,
  );

  return (
    <OsrTabs
      actions={
        <Spacer direction="vertical" size="small">
          <Flex alignItems="center" justify="space-between">
            <Flex column style={{ width: '33%' }}>
              <RangePicker
                allowClear
                inputReadOnly={true}
                defaultValue={[startOfYear(new Date()), endOfYear(new Date())]}
                onChange={(e) => {
                  props.onChangeRangeDate(
                    e && e[0] ? new Date(endOfDay(e[0])) : e?.[0] ?? null,
                    e && e[1] ? new Date(endOfDay(e[1])) : e?.[1] ?? null,
                  );
                }}
              />
            </Flex>

            <Flex>
              <Radio.Group
                defaultValue={'all'}
                onChange={(e) => props.onChangeOffice(e.target.value)}
              >
                <Radio value={'all'}>
                  {t('pages.osr.filters.office', {
                    context: 'all',
                  })}
                </Radio>
                <Radio value={'Europe'}>
                  {t('pages.osr.filters.office', {
                    context: 'europe',
                  })}
                </Radio>
                <Radio value={'France'}>
                  {t('pages.osr.filters.office', {
                    context: 'france',
                  })}
                </Radio>
                <Radio value={'InterDetail'}>
                  {t('pages.osr.filters.office', {
                    context: 'international',
                  })}
                </Radio>
                <Radio value={'Others'}>
                  {t('pages.osr.filters.office', {
                    context: 'others',
                  })}
                </Radio>
              </Radio.Group>
            </Flex>
          </Flex>

          <Flex>
            <Button
              loading={loading}
              type="primary"
              onClick={() => generateExcel('RENEWS')}
            >
              Exporter les Renouvellements
            </Button>
          </Flex>
        </Spacer>
      }
      tabsItems={[
        {
          key: 'toRenew',
          label: `Dessins & modèles à renouveler (${toRenewTableProps.pagination?.total})`,
          children: (
            <Spacer direction="vertical" size="small">
              <Flex justify="flex-end">
                <OsrColorDescription
                  colors={[
                    {
                      color: '#90EE90',
                      label: 'Renouv. Possible',
                    },
                    {
                      color: '#FFA502',
                      label: 'Délai de grace',
                    },
                    {
                      color: '#f27979',
                      label: 'Hors délai',
                    },
                  ]}
                />
              </Flex>
              <Table
                name="designToRenew"
                defaultColumns={DESIGN_RENEW_COLUMNS}
                dataSource={toRenewData}
                handleTableRowColor={handleToRenewDesignColor}
                {...toRenewTableProps}
              />
            </Spacer>
          ),
        },
        {
          key: 'renewed',
          label: `Dessins & modèles renouvelés (${renewedTableProps.pagination?.total})`,
          children: (
            <Spacer direction="vertical" size="small">
              <Flex justify="flex-end">
                <OsrColorDescription
                  colors={[
                    {
                      color: '#c9ffb2',
                      label: 'Complet',
                    },
                    {
                      color: '#ffbf00',
                      label: 'AR manquant',
                    },
                  ]}
                />
              </Flex>
              <Table
                name="designRenewed"
                confirmationReceipt={{
                  value: props.filters.ar ?? false,
                  onChange: (e: boolean) =>
                    props.onChangeConfirmationReceipt(e),
                }}
                defaultColumns={DESIGN_RENEW_COLUMNS}
                dataSource={renewedData}
                handleTableRowColor={handleRenewedDesignColor}
                {...renewedTableProps}
              />
            </Spacer>
          ),
        },
      ]}
    ></OsrTabs>
  );
};

export default RenewalTracking;
