import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { IFiltersActions } from './useOsrFilters';
import RenewalTracking from 'business/resources/osr/components/design/renewalTracking';
import { useTranslation } from 'react-i18next';
import BudgetTracking from 'business/resources/osr/components/design/budgetTracking';
import DesignAcumass from 'business/resources/osr/components/design/designAcumass';
import DesignAnomaly from 'business/resources/osr/components/design/designAnomaly';
import { isBdlAdmin } from 'business/admin/services/permission';
import { OsrFilterState } from './types';

const useGetOsrTabs = (filters: OsrFilterState, actions: IFiltersActions) => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const isBdlAdminUser = isBdlAdmin(user);

  const items = [
    {
      key: 'RenewDesignCase',
      label: t('pages.osr.title', { context: 'tabRenew' }),
      children: (
        <RenewalTracking
          filters={filters}
          onChangeConfirmationReceipt={actions.onChangeConfirmationReceipt}
          onChangeOffice={actions.onChangeOffice}
          onChangeRangeDate={actions.onChangeRangeDate}
        />
      ),
    },
  ];

  if (isBdlAdminUser) {
    items.push({
      key: 'budget',
      label: 'Budget',
      children: <BudgetTracking filters={filters} actions={actions} />,
    });
  }

  return [
    ...items,
    {
      key: 'Acumass',
      label: 'Mise à jour Acumass',
      children: <DesignAcumass filters={filters} />,
    },
    {
      key: 'DesignAnomaly',
      label: t('pages.osr.title', { context: 'tabAnomaly' }),
      children: <DesignAnomaly filters={filters} />,
    },
  ];
};

export default useGetOsrTabs;
